<template>
    <div>
        <el-dialog
            center
            title="新增客户"
            :visible="visible"
            :before-close="closeDialog"
            width="848px"
            style="min-width:808px"
        >
            <div>
                <el-form
                    ref="clientForm"
                    :model="clientForm"
                    :rules="rules"
                    class="demo-ruleForm"
                    size="mini"
                    label-width="80px"
                >
                    <!-- 基本信息title -->
                    <el-row>
                        <el-col :span="24">
                            <div class="title">基本信息</div>
                        </el-col>
                    </el-row>
                    <!-- 手机-性别-年龄 -->
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="手机号:" prop="mobileNumber">
                                <el-input
                                    type="number"
                                    v-model="clientForm.mobileNumber"
                                    oninput="value=value.replace(/[^\d]/g,'')"
                                    :maxlength="11"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="性别:" prop="sex">
                                <el-radio-group v-model="clientForm.sex" size="mini">
                                    <el-radio-button
                                        v-for="item in sexArr"
                                        :key="item.number"
                                        :value="item.number"
                                        :label="item.number"
                                    >{{ item.cnVal }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="年龄:" prop="age">
                                <el-input
                                    v-model.number="clientForm.age"
                                    oninput="value=value.replace(/[^\d]/g,'')"
                                >
                                    <template slot="append">岁</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 姓名-资金需求-居住地 -->
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="姓名:" prop="name">
                                <el-input v-model="clientForm.name" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="资金需求:" prop="fundNeeds">
                                <el-input
                                    v-model="clientForm.fundNeeds"
                                    oninput="value=value.replace(/[^\d]/g,'')"
                                >
                                    <template slot="append">万</template>
                                </el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="居住地:" prop="residence">
                                <!-- {{clientForm.residence[clientForm.residence.length - 1]}} -->
                                <el-cascader
                                    v-model="clientForm.residence"
                                    :show-all-levels="false"
                                    :options="areasOption"
                                    :props="{ expandTrigger: 'hover', label: 'name', value: 'name' }"
                                />
                                <!-- <el-input v-model="clientForm.age"></el-input> -->
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 跟进阶段-意向度 -->
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="跟进阶段:" prop="followStage">
                                <el-radio-group v-model="clientForm.followStage" size="mini">
                                    <el-radio-button
                                        v-for="item in followArr"
                                        :key="item.number"
                                        :value="item.number"
                                        :label="item.number"
                                    >{{ item.cnVal }}</el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="意向度:" prop="purposeLevel">
                                <el-radio-group v-model="clientForm.purposeLevel" size="mini">
                                    <el-radio-button
                                        v-for="item in purposeArr"
                                        :key="item.number"
                                        :value="item.number"
                                        :label="item.number"
                                    >
                                        <el-tooltip
                                            class="item"
                                            effect="dark"
                                            :content="item.content"
                                            placement="bottom-end"
                                        >
                                            <p>{{ item.cnVal }}</p>
                                        </el-tooltip>
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <!-- 资质title 可贷点吧 -->
                    <el-row type="flex" class="title" justify="space-between">
                        <el-col :span="12">资质</el-col>
                        <el-col :span="12">
                            <div
                                style="display:flex;justify-content: flex-end;align-items: center;"
                            >
                                <div style="margin-right:10px">星级</div>
                                <!-- <el-rate v-model="clientForm.qualityLevel"></el-rate> -->
                                <star :score.sync="clientForm.qualityLevel" />
                                <div class="quality-text" @click="showEdit">可贷点识别</div>
                            </div>
                        </el-col>
                    </el-row>
                    <!-- 车辆 -->
                    <el-row>
                        <el-col :span="4" class="switch-area">
                            <el-switch
                                v-model="clientForm.vehicle.has"
                                active-text="车辆"
                                :active-value="1"
                                :inactive-value="0"
                            />
                        </el-col>
                        <el-col :span="20" class="info-area">
                            <div v-if="clientForm.vehicle.has == 1">
                                <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="裸车价："
                                        style="margin-bottom:0px"
                                        prop="vehicle.nakedPrice"
                                    >
                                        <el-input
                                            v-model="clientForm.vehicle.nakedPrice"
                                            placeholder="请输入数字"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">万</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7.5" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="付款："
                                        style="margin-bottom:0px"
                                        prop="vehicle.paymentType"
                                    >
                                        <el-radio-group
                                            v-model="clientForm.vehicle.paymentType"
                                            size="mini"
                                        >
                                            <el-radio-button
                                                v-for="item in carPaymentType"
                                                :key="item.number"
                                                :value="item.number"
                                                :label="item.number"
                                            >{{ item.cnVal }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div
                                v-else
                                style="text-align:center;height:40px;line-height:40px;color:#ccc"
                            >暂 无 内 容</div>
                        </el-col>
                    </el-row>
                    <!-- 住房 -->
                    <el-row>
                        <el-col :span="4" class="switch-area">
                            <el-switch
                                v-model="clientForm.house.has"
                                active-text="房产"
                                :active-value="1"
                                :inactive-value="0"
                            />
                        </el-col>
                        <el-col :span="20" class="info-area">
                            <div v-if="clientForm.house.has == 1">
                                <el-col
                                    :span="8"
                                    style="text-align:center;padding: 6px 0;"
                                    prop="house.marketPrice"
                                >
                                    <el-form-item
                                        label="房价："
                                        prop="house.marketPrice"
                                        style="margin-bottom:0px"
                                    >
                                        <el-input
                                            v-model="clientForm.house.marketPrice"
                                            placeholder="请输入数字"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">万</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7.5" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="付款："
                                        style="margin-bottom:0px"
                                        prop="house.paymentType"
                                    >
                                        <el-radio-group
                                            v-model="clientForm.house.paymentType"
                                            size="mini"
                                        >
                                            <el-radio-button
                                                v-for="item in carPaymentType"
                                                :key="item.number"
                                                :value="item.number"
                                                :label="item.number"
                                            >{{ item.cnVal }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="类型："
                                        style="margin-bottom:0px"
                                        prop="house.type"
                                    >
                                        <el-select
                                            v-model="clientForm.house.type"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in houseType"
                                                :key="item.number"
                                                :label="item.cnVal"
                                                :value="item.number"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div
                                v-else
                                style="text-align:center;height:40px;line-height:40px;color:#ccc"
                            >暂 无 内 容</div>
                        </el-col>
                    </el-row>
                    <!-- 公积金 -->
                    <el-row>
                        <el-col :span="4" class="switch-area">
                            <el-switch
                                v-model="clientForm.gongjijin.has"
                                active-text="公积金"
                                :active-value="1"
                                :inactive-value="0"
                            />
                        </el-col>
                        <el-col :span="20" class="info-area">
                            <div v-if="clientForm.gongjijin.has == 1">
                                <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="月缴："
                                        style="margin-bottom:0px"
                                        prop="gongjijin.monthlyFee"
                                    >
                                        <el-input
                                            v-model="clientForm.gongjijin.monthlyFee"
                                            placeholder="请输入数字"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="年限："
                                        style="margin-bottom:0px"
                                        prop="gongjijin.payYears"
                                    >
                                        <el-input
                                            v-model="clientForm.gongjijin.payYears"
                                            placeholder="请输入数字"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">年</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="6.5" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="类型："
                                        style="margin-bottom:0px"
                                        prop="gongjijin.type"
                                    >
                                        <el-radio-group
                                            v-model="clientForm.gongjijin.type"
                                            size="mini"
                                        >
                                            <el-radio-button
                                                v-for="item in gongjijin"
                                                :key="item.number"
                                                v-model="clientForm.gongjijin.type"
                                                :value="item.number"
                                                :label="item.number"
                                            >{{ item.cnVal }}</el-radio-button>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div
                                v-else
                                style="text-align:center;height:40px;line-height:40px;color:#ccc"
                            >暂 无 内 容</div>
                        </el-col>
                    </el-row>
                    <!-- 社保 -->
                    <el-row>
                        <el-col :span="4" class="switch-area">
                            <el-switch
                                v-model="clientForm.shebao.has"
                                active-text="社保"
                                :active-value="1"
                                :inactive-value="0"
                            />
                        </el-col>
                        <el-col :span="20" class="info-area">
                            <div v-if="clientForm.shebao.has == 1">
                                <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="年限："
                                        style="margin-bottom:0px"
                                        prop="shebao.payYears"
                                    >
                                        <el-input
                                            v-model="clientForm.shebao.payYears"
                                            placeholder="请输入数字"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">年</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="类型："
                                        style="margin-bottom:0px"
                                        prop="shebao.type"
                                    >
                                        <el-select
                                            v-model="clientForm.shebao.type"
                                            placeholder="请选择"
                                        >
                                            <el-option
                                                v-for="item in shebao"
                                                :key="item.number"
                                                :label="item.cnVal"
                                                :value="item.number"
                                            />
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div
                                v-else
                                style="text-align:center;height:40px;line-height:40px;color:#ccc"
                            >暂 无 内 容</div>
                        </el-col>
                    </el-row>
                    <!-- 保单 -->
                    <el-row>
                        <el-col
                            :span="4"
                            class="switch-area"
                            style="border-bottom: 1px solid #ccc;"
                        >
                            <el-switch
                                v-model="clientForm.baodan.has"
                                active-text="保单"
                                :active-value="1"
                                :inactive-value="0"
                            />
                        </el-col>
                        <el-col :span="20" class="info-area" style="border-bottom: 1px solid #ccc;">
                            <div v-if="clientForm.baodan.has == 1">
                                <el-col :span="8" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="年缴："
                                        prop="baodan.annualFee"
                                        style="margin-bottom:0px"
                                    >
                                        <el-input
                                            v-model="clientForm.baodan.annualFee"
                                            placeholder="请输入数字"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="7" style="text-align:center;padding: 6px 0;">
                                    <el-form-item
                                        label="年限："
                                        style="margin-bottom:0px;"
                                        prop="baodan.payYears"
                                    >
                                        <el-input
                                            v-model="clientForm.baodan.payYears"
                                            placeholder="请输入数字"
                                            oninput="value=value.replace(/[^\d]/g,'')"
                                        >
                                            <template slot="append">年</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div
                                v-else
                                style="text-align:center;height:40px;line-height:40px;color:#ccc"
                            >暂 无 内 容</div>
                        </el-col>
                    </el-row>
                    <!-- 跟进内容 -->
                    <el-row style="margin-top: 10px;">
                        <el-col :span="24">
                            <div>
                                <el-input
                                    v-model="clientForm.followContent"
                                    type="textarea"
                                    :autosize="{ minRows: 4, maxRows: 5 }"
                                    placeholder="请输入本次跟进内容"
                                    maxlength="128"
                                    show-word-limit
                                />
                            </div>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submit">保存</el-button>
                <el-button @click="closeDialog">取 消</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="可贷点识别（包括客户家人和朋友）"
            :visible="showQuality"
            width="500px"
            :before-close="closeQuality"
            center
        >
            <div>
                <!-- 普通 -->
                <div class="normal">普通可贷点：</div>
                <div class="content">
                    <div class="content-item">
                        <div class="content-num">1</div>
                        <div class="content-info">有银行代发工资5000以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">2</div>
                        <div class="content-info">在本地（杭州）有社保</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">3</div>
                        <div class="content-info">在本地（杭州）有公积金</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">4</div>
                        <div class="content-info">名下有车辆，年限在10年内，非运营车、公司车、货车</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">5</div>
                        <div class="content-info">在本地（杭州）执照满1年以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">6</div>
                        <div class="content-info">有保单年缴2500以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">7</div>
                        <div class="content-info">有微粒贷5000以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">8</div>
                        <div class="content-info">名下有回迁安置房</div>
                    </div>
                </div>
                <div class="excellent">优质可贷点：</div>
                <div class="content">
                    <div class="content-item">
                        <div class="content-num">1</div>
                        <div class="content-info">有公积金双边1000以上</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">2</div>
                        <div class="content-info">外地人社保1年，本地人社保满半年</div>
                    </div>
                    <div class="content-item" style="height:60px">
                        <div class="content-num" style="line-height:60px">3</div>
                        <div
                            class="content-info"
                            style="line-height: 29px;"
                        >名下有全款房、按揭房、公寓、厂房、排屋、别墅、商铺（非自建房、无产证的房子）</div>
                    </div>
                    <div class="content-item" style="height:60px">
                        <div class="content-num" style="line-height:60px">4</div>
                        <div
                            class="content-info"
                            style="line-height: 29px;"
                        >名下有车，裸车价20万以上，年限在3年内，非运营车、公司车、货车</div>
                    </div>
                    <div class="content-item" style="height:60px">
                        <div class="content-num" style="line-height:60px">5</div>
                        <div
                            class="content-info"
                            style="line-height: 29px;"
                        >优质工作单位：公务员、事业单位、知名大企业、世界500强、中国500强、上市公司、国企、垄断行业</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">6</div>
                        <div class="content-info">全国公司法人或者大股东，年缴税2万以上，缴税满2年</div>
                    </div>
                    <div class="content-item">
                        <div class="content-num">7</div>
                        <div class="content-info">在杭州做生意（有营业执照），季度结息28元以上</div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import areas from '@/assets/js/areaServices';
import { add_client } from '@/api/my_client.js';
export default {
    name: 'ShowDialog',
    components: {
        star: () => import('@/components/templates/star'),
    },
    props: { showDialog: { default: false } },
    data() {
        return {
            visible: false,
            showQuality: false,
            // departments: [],
            departVal: '',
            clientForm: {
                mobileNumber: '',
                fundNeeds: '',
                name: '',
                sex: '-1',
                age: '',
                followStage: 1,
                purposeLevel: '0',
                qualityLevel: 1,
                followContent: '',
                residence: '',
                house: {
                    has: 0,
                    marketPrice: 0,
                    type: 0,
                    paymentType: 0,
                },
                vehicle: {
                    has: 0,
                    nakedPrice: 0,
                    paymentType: 0,
                },
                shebao: {
                    has: 0,
                    type: 0,
                    payYears: 0,
                },
                gongjijin: {
                    has: 0,
                    type: 0,
                    payYears: 0,
                    monthlyFee: 0,
                },
                baodan: {
                    has: 0,
                    annualFee: 0,
                    payYears: 0,
                },
            },
            //判断表单的基本格式
            rules: {
                name: [
                    {
                        min: 1,
                        max: 32,
                        message: '长度是1-32个字符之间',
                        trigger: 'blur',
                        required: true,
                    },
                    { validator: this.formValidation.name, trigger: 'blur' },
                ],
                age: [{ validator: this.formValidation.ldq, trigger: 'blur' }],
                fundNeeds: [
                    { validator: this.formValidation.ldyw, trigger: 'blur' },
                ],
                mobileNumber: [
                    {
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur',
                    },
                    {
                        validator: this.formValidation.mobile,
                        trigger: 'blur',
                    },
                ],
                vehicle: {
                    nakedPrice: [
                        {
                            validator: this.formValidation.ldyw,
                            trigger: 'blur',
                        },
                    ],
                },
                house: {
                    marketPrice: [
                        {
                            validator: this.formValidation.ldyw,
                            trigger: 'blur',
                        },
                    ],
                },
                gongjijin: {
                    monthlyFee: [
                        {
                            validator: this.formValidation.ldsw,
                            trigger: 'blur',
                        },
                    ],
                    payYears: [
                        { validator: this.formValidation.ldq, trigger: 'blur' },
                    ],
                },
                shebao: {
                    payYears: [
                        { validator: this.formValidation.ldq, trigger: 'blur' },
                    ],
                },
                baodan: {
                    annualFee: [
                        {
                            validator: this.formValidation.ldyy,
                            trigger: 'blur',
                        },
                    ],
                    payYears: [
                        { validator: this.formValidation.ldq, trigger: 'blur' },
                    ],
                },
            },
            sexArr: [],
            followArr: [],
            purposeArr: [],
            carPaymentType: [],
            houseType: [],
            gongjijin: [],
            shebao: [],
            baodan: [],
            areasOption: [],
        };
    },
    created() {
        if (this.debugOpen) console.log('addClientDialog.vue -> created()');
        this.sexArr = this.common.allValueNeed('sex');
        this.followArr = this.common.allValueNeed('client-follow-stage');
        this.purposeArr = this.common.allValueNeed('loan-purpose-level');
        this.purposeArr.forEach((item) => {
            if (item.number == 0) {
                item.content = '沟通过贷款问题，或暂无意向';
            } else if (item.number == 1) {
                item.content = '有贷款意向，还存在较多顾虑';
            } else if (item.number == 2) {
                item.content = '有明确贷款意向，不急需用钱';
            } else if (item.number == 3) {
                item.content = '迫切需要贷款，急需用钱';
            }
        });
        this.carPaymentType = this.common.allValueNeed('vehicle-pay-type');
        this.houseType = this.common.allValueNeed('house-type');
        this.gongjijin = this.common.allValueNeed('gongjijin-pay-type');
        this.shebao = this.common.allValueNeed('shebao-type');
        this.baodan = this.common.allValueNeed('loan-purpose-level');
        this.areasOption = areas.getAll();
        // this.getDepartment();
    },
    mounted() {
        if (this.debugOpen) console.log('addClientDialog.vue -> mounted()');
    },
    updated() {
        if (this.debugOpen) console.log('addClientDialog.vue -> updated()');
    },
    methods: {
        getTreeData(data) {
            // 循环遍历json数据
            for (let i = 0; i < data.length; i++) {
                if (data[i].children.length < 1) {
                    // children若为空数组，则将children设为undefined
                    data[i].children = undefined;
                } else {
                    // children若不为空数组，则继续 递归调用 本方法
                    this.getTreeData(data[i].children);
                }
            }
            return data;
        },
        // getDepartment() {
        //   departments_staff().then((res) => {
        //     this.departments = res.data.list;
        //     console.log(res.data.list);
        //   });
        // },
        closeDialog() {
            this.visible = false;
            this.$emit('update:showDialog', false);
            this.$refs.clientForm.resetFields();
            this.clientForm.house.has = 0;
            this.clientForm.vehicle.has = 0;
            this.clientForm.shebao.has = 0;
            this.clientForm.gongjijin.has = 0;
            this.clientForm.baodan.has = 0;
            this.clientForm.followContent = '';
            this.clientForm.qualityLevel = 1;
            console.log(this.clientForm);
        },
        submit() {
            // console.log(this.departVal);
            // this.$emit("departVal", this.departVal);
            this.$refs.clientForm.validate((valid) => {
                if (valid) {
                    let that = this;
                    if (this.clientForm.residence) {
                        this.clientForm.residence =
                            this.clientForm.residence[
                                this.clientForm.residence.length - 1
                            ];
                    }
                    console.log(this.clientForm);
                    add_client(this.clientForm).then((res) => {
                        if (res.code == 0) {
                            that.$message({
                                message: '新增客户成功',
                                type: 'success',
                            });
                            that.closeDialog();
                            that.$parent.$parent.getTableData();
                            // this.$refs.clientForm.resetFields();
                            // this.ruleForm = this.chushifrom;
                        } else {
                            that.$message.error(res.error);
                        }
                    });
                } else {
                    // this.$message.error("请检查输入框的内容");
                    return false;
                }
            });
        },
        selDepartVal() {
            this.$refs.cascaderHandle.dropDownVisible = false;
        },
        //展示可贷点
        showEdit() {
            console.log('this.clientForm', this.clientForm);
            this.visible = false;
            this.showQuality = true;
            // this.$emit('showQuality')
        },
        closeQuality() {
            this.visible = true;
            this.showQuality = false;
        },
    },
    watch: {
        showDialog() {
            this.visible = this.showDialog;
        },
    },
};
</script>
<style lang="less" scoped>
.title {
    background-color: rgba(249, 249, 249, 1);
    padding: 10px;
    font-family: 'Arial Normal', 'Arial';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    color: #333;
    margin-bottom: 10px;
}
/deep/ .el-radio-button--mini .el-radio-button__inner {
    padding: 7px;
}
/deep/ .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    color: #4086ec;
    background-color: #ecf3fd;
    box-shadow: 0 0px 0px #ccc;
    border-color: #dcdfe6;
}
/deep/ .el-radio-button__inner {
    color: #aaa;
}
/deep/ .el-input-group__append {
    padding: 0 10px;
}
.switch-area {
    height: 50px;
    line-height: 50px;
    padding-left: 26px;
    border: 1px solid #ccc;
    border-bottom: none;
}
.info-area {
    border: 1px solid #ccc;
    border-left: 0;
    border-bottom: none;
    line-height: 50px;
    height: 50px;
}
/deep/ .rate .star-wrap .icon-star {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px !important;
}
/deep/ .rate .star-wrap .icon-star-o {
    width: 15px !important;
    height: 15px !important;
    margin-right: 5px !important;
}
.quality-text {
    color: #aaaaaa;
    font-size: 14px;
    &:hover {
        color: #4086ec;
        cursor: pointer;
    }
}
.normal,
.excellent {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 10px;
}
.content {
    border: 1px solid #aaa;
}
.content-item {
    display: flex;
    align-items: center;
    // height: 100%;
    height: 40px;
    // border-bottom: 1px solid #aaa;
    .content-num {
        width: 50px;
        // height: 40px;
        line-height: 40px;
        height: 100%;
        text-align: center;
        border-right: 1px solid #aaa;
    }
    .content-info {
        height: 100%;
        margin-left: 10px;
        vertical-align: middle;

        // height: 40px;
        width: 100%;
        line-height: 40px;
    }
    &:not(:last-child) {
        border-bottom: 1px solid #aaa;
    }
}
</style>
